<template>
  <Drawer
    width="1200"
    scrollable
    loading
    class-name="i-modal"
    v-model="isShow"
    title="商品详细信息"
  >
    <Row :gutter="12">
      <Form
        :model="obj"
        ref="productForm"
        class="modelViewFromCheck"
        style="width: 100%"
        :label-width="120"
      >
        <Row>
          <Col span="24">
            <div style="padding-left: 10px">
              <Row :gutter="8">
                <div id="fauplode">
                  <FormItem label="商品图片:">
                    <div
                      style="display: inline-block; margin: 0px 5px"
                      v-for="(item, index) in files"
                      :key="index"
                    >
                      <div id="fauplode">
                        <div
                          @click="
                            handleViewImg(item._file);
                            cIndex = index;
                          "
                          class="files-list"
                        >
                          <img width="58" height="58" :src="item._file" />
                        </div>
                      </div>
                    </div>
                  </FormItem>
                </div>
              </Row>
            </div>
          </Col>
        </Row>
        <Row class="text-cell">
          <Col class="title" span="3">商品ID:</Col>
          <Col class="value" span="5">{{ obj.ID }}</Col>
          <Col class="title" span="3">商品编号:</Col>
          <Col class="value" span="5">{{ obj.Code }}</Col>
          <Col class="title" span="3">商品名称:</Col>
          <Col class="value" span="5">{{ obj.Name }}</Col>
        </Row>
        <Row class="text-cell">
          <Col class="title" span="3">商品品牌:</Col>
          <Col class="value" span="5">{{ getBrandName(obj) }}</Col>
          <Col class="title" span="3">商品分类:</Col>
          <Col class="value" span="5">{{ obj.ClassName }}</Col>
          <Col class="title" span="3">展示销量:</Col>
          <Col class="value" span="5">{{ obj.ShowSells }}</Col>
        </Row>
        <Row class="text-cell">
          <Col class="title" span="3">销售价:</Col>
          <Col class="value" span="5">{{ obj.Price }}</Col>
          <Col class="title" span="3">内部价:</Col>
          <Col class="value" span="5">{{ obj.InsidePrice }}</Col>
          <Col class="title" span="3">成本价:</Col>
          <Col class="value" span="5">{{ obj.PurchasePrice }}</Col>
        </Row>
        <Row class="text-cell">
          <Col class="title" span="3">一级提成:</Col>
          <Col class="value" span="5">{{ obj.Bonus1 }}</Col>
          <Col class="title" span="3">二级提成:</Col>
          <Col class="value" span="5">{{ obj.Bonus2 }}</Col>
          <Col class="title" span="3">商品库存:</Col>
          <Col class="value" span="5">{{ obj.Stock }}</Col>
        </Row>
        <Row class="text-cell">
          <Col class="title" span="3">最小购买:</Col>
          <Col class="value" span="5">{{ obj.MinNum }}</Col>
          <Col class="title" span="3">最大购买:</Col>
          <Col class="value" span="5">{{ obj.MaxNum }}</Col>
          <Col class="title" span="3">是否售罄:</Col>
          <Col class="value" span="5">
            {{ obj.IsSellOut === null ? "" : obj.IsSellOut ? "是" : "否" }}
          </Col>
        </Row>
        <Row class="text-cell">
          <Col class="title" span="3">商品单位:</Col>
          <Col class="value" span="5">{{ obj.Unit }}</Col>
          <Col class="title" span="3">商品标签:</Col>
          <Col class="value" span="5">{{ obj.Label }}</Col>
          <Col class="title" span="3">商品状态:</Col>
          <Col class="value" span="5"> {{ obj.StatusCN }} </Col>
        </Row>
        <Row class="text-cell">
          <Col class="title" span="3">上架时间:</Col>
          <Col class="value" span="5">{{ obj.SaleBeginDate }}</Col>
          <Col class="title" span="3">下架时间:</Col>
          <Col class="value" span="5"> {{ obj.SaleEndDate }} </Col>
          <Col class="title" span="3">主播提成:</Col>
          <Col class="value" span="5">{{ obj.AnchorBonus }}</Col>
        </Row>
        <Row class="text-cell">
          <Col class="title" span="3">商品栏目:</Col>
          <Col class="value" span="21">
            <Tag
              color="blue"
              v-for="(item, index) in getTopicName(obj)"
              :key="index"
              >{{ item }}</Tag
            >
          </Col>
        </Row>
        <Row class="text-cell">
          <Col class="title" span="3">商品分享描述:</Col>
          <Col class="value" span="21">{{ obj.ShareDescription }}</Col>
        </Row>

        <Divider orientation="left">商品规格</Divider>
        <Table
          style="margin-top: 10px"
          border
          :columns="[
            { title: '商品规格', key: 'Model' },
            { title: '库存', key: 'Stock' },
          ]"
          :data="obj.SubProductList"
        >
        </Table>
        <Divider orientation="left">商品描述</Divider>
        <div v-html="productDesc"></div>
      </Form>
    </Row>
    <Modal
      title="查看大图"
      class-name="i-modal"
      width="400"
      v-model="isShowViewImage"
      :footer-hide="true"
    >
      <Carousel
        loop
        easing="none"
        :arrow="files.length > 1 ? 'always' : 'never'"
        :dots="files.length > 1 ? 'inside' : 'none'"
        class="self_carousel"
        v-model="cIndex"
        style="max-height: 70vh"
        v-if="isShowViewImage"
      >
        <CarouselItem
          v-for="i in files"
          class="self_carousel_item"
          :key="i._key"
        >
          <img :src="$setImagePrefix(i._key)" />
        </CarouselItem>
      </Carousel>
      <template slot="footer">
        <Button @click="isShowViewImage = false">关闭</Button>
      </template>
    </Modal>
    <Modal
      title="查看大图"
      class-name="i-modal"
      width="400"
      v-model="isShowViewImageSingle"
      :footer-hide="true"
    >
      <img :src="ViewImg" v-if="isShowViewImageSingle" style="width: 100%" />
      <template slot="footer">
        <Button @click="isShowViewImageSingle = false">关闭</Button>
      </template>
    </Modal>
  </Drawer>
</template>

<script>
import { mapActions } from "vuex";
import bizEnum from "@/libs/bizEnum";
export default {
  name: "add-product-modal",
  data() {
    return {
      tableHeight: 0,
      BigImageView: false,
      isShowViewImageSingle: false,
      cIndex: 0,
      isShowViewImage: false,
      productDesc: "",
      productPrameter: "",
      ViewImg: "",
      isShow: false,
      copyID: null,
      isAdd: true,
      SaveLoading: false,
      loading: false,
      files: [],
      transparentFiles: [],
      videoFiles: [],
      DefaultImagePath: "",
      CheckBonusStr: "",
      obj: {},
      ModelNewClass: [],
    };
  },
  created() {
    this.getProductBrandInfo();
    this.getProductClassInfo();
  },
  watch: {
    isShow: function (val) {
      if (val) {
        this.ModelNewClass = this.ProductClassInfo;
        this.obj.IsChild = this.obj.IsChild ? this.obj.IsChild : false;
        if (this.obj.ProductMedia && this.obj.ProductMedia.length !== 0) {
          let files = this.obj.ProductMedia.filter((i) => i.Type === 10).map(
            (item) => {
              return {
                _key: item.ImagePath,
                _file: this.$setImagePrefix(item.ImagePath),
                success: true,
                Index: item.Index,
                IsDefault: item.IsDefault || false,
              };
            }
          );
          if (files.length === 1) {
            files[0].IsDefault = true;
            this.DefaultImagePath = files[0]._key;
          } else {
            this.DefaultImagePath = this.obj.DefaultImagePath;
          }
          let transFile = this.obj.TransparentImagePath
            ? [
                {
                  _key: this.obj.TransparentImagePath,
                  _file: this.$setImagePrefix(this.obj.TransparentImagePath),
                  success: true,
                },
              ]
            : [];
          this.transparentFiles = JSON.parse(JSON.stringify(transFile));

          let videoFiles = this.obj.ProductMedia.filter(
            (i) => i.Type === 20
          ).map((item) => {
            return {
              _key: item.VideoCover,
              _file: this.$setImagePrefix(item.VideoCover),
              success: true,
              Index: item.Index,
              Name: item.Name,
              VideoPath: item.ImagePath,
            };
          });
          this.videoFiles = JSON.parse(JSON.stringify(videoFiles));
          this.files = JSON.parse(JSON.stringify(files));
        } else {
          this.files = [];
          this.transFile = [];
          this.videoFiles = [];
        }
        if (this.obj.CheckBonus !== null) {
          this.CheckBonusStr = this.obj.CheckBonus ? "1" : "0";
        }
        if (this.obj.Service) {
          this.obj.Service = this.obj.Service.split(",").map((i) => Number(i));
        }
        if (this.obj.Limit) {
          this.obj.Limit = this.obj.Limit.split(",").map((i) => Number(i));
        }
        if (
          this.obj.ProductContent &&
          this.obj.ProductContent.findIndex((c) => c.Type === 10) >= 0
        ) {
          this.productDesc = this.obj.ProductContent.find(
            (i) => i.Type === 10
          ).Content;
        } else {
          this.productDesc = "";
        }
        if (
          this.obj.ProductContent &&
          this.obj.ProductContent.findIndex((c) => c.Type === 20) >= 0
        ) {
          this.productPrameter = this.obj.ProductContent.find(
            (i) => i.Type === 20
          ).Content;
        } else {
          this.productPrameter = "";
        }
        if (!this.obj.ID) {
          this.ModelNewClass = [];
        }
      }
    },
  },
  methods: {
    ...mapActions(["getProductBrandInfo", "getProductClassInfo"]),
    handleViewImg(src) {
      this.ViewImg = src;
      this.isShowViewImage = true;
    },
    handleViewImgSingle(src) {
      this.ViewImg = src;
      this.isShowViewImageSingle = true;
    },
    getBrandName(row) {
      if (row.BrandID) {
        let brand =
          this.ProductBrandInfo &&
          this.ProductBrandInfo.find((it) => it.ID === row.BrandID);
        return brand ? brand.Name : "";
      } else {
        return "";
      }
    },
    getClassName(row) {
      if (row.ClassCode) {
        let cls = this.ProductClassInfo.find((it) => it.Code === row.ClassCode);
        return cls ? cls.Name : "";
      } else {
        return "";
      }
    },
    getServiceName(row) {
      const _this = this;
      let names = [];
      if (row.Service && row.Service.length) {
        row.Service.forEach((i) => {
          let ser = _this.ProductServiceEnum.find((it) => it.ID === i);
          if (ser) names.push(ser.CN);
        });
        return names;
      } else {
        return [];
      }
    },
    getLimitName(row) {
      const _this = this;
      let names = [];
      if (row.Limit && row.Limit.length) {
        row.Limit.forEach((i) => {
          let ser = _this.ProductLimitEnum.find((it) => it.ID === i);
          if (ser) names.push(ser.CN);
        });
        return names;
      } else {
        return [];
      }
    },
    getTopicName(row) {
      const _this = this;
      let names = [];
      if (row.Topic && row.Topic.length) {
        row.Topic.forEach((i) => {
          let ser = _this.ProductTopicEnum.find((it) => it.ID === i);
          if (ser) names.push(ser.CN);
        });
        return names;
      } else {
        return [];
      }
    },
  },
  computed: {
    token() {
      return this.$store.state.app.qiniuToken;
    },
    UploadType() {
      return bizEnum.UploadType;
    },
    ProductBrandInfo() {
      return this.$store.state.brandsList || [];
    },
    ProductClassInfo() {
      return this.$store.state.product.classList || [];
    },
    ProductFunctionInfo() {
      return this.$store.state.product.functionList || [];
    },
    ProductStatusEnum() {
      return bizEnum.ProductStatusEnum;
    },
    ProductTopicEnum() {
      return bizEnum.ProductTopicEnum;
    },
    ProductServiceEnum() {
      return bizEnum.ProductServiceEnum;
    },
    ProductLimitEnum() {
      return bizEnum.ProductLimitEnum;
    },
  },
  components: {},
};
</script>

<style lang="less">
.tiny-parent .tox-tinymce {
  height: 600px !important;
}

.product-img-upload {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  .video-box {
    width: 180px;
    height: auto;
    position: relative;
    list-style: none;
    margin-right: 5px;

    .file-item-cover {
      position: absolute;
      width: 150px;
      height: 150px;
      top: 0;
      left: 0;
      display: none;
      justify-content: flex-end;
      align-items: flex-start;
      border: 1px dashed #dcdee2;
      border-radius: 4px;

      & > i {
        cursor: pointer;
        position: relative;
        top: 0px;
        right: 2px;
      }
    }

    video {
      width: 180px;
      height: 150px;
    }
  }

  .img-box {
    width: 58px;
    height: auto;
    position: relative;
    list-style: none;
    margin-right: 10px;

    img {
      width: 58px;
      height: 58px;
    }

    &:hover .file-item-cover {
      display: flex;
    }

    .file-item-cover {
      position: absolute;
      width: 58px;
      height: 58px;
      top: 0;
      left: 0;
      display: none;
      justify-content: flex-end;
      align-items: flex-start;
      border: 1px dashed #dcdee2;
      border-radius: 4px;

      & > i {
        cursor: pointer;
        position: relative;
        top: -6px;
        right: -6px;
      }
    }
  }
}
</style>
